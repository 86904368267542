import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

//Share buttons
import FacebookShare from './FacebookShare';
import TwitterShare from './TwitterShare';
import LinkedinShare from './LinkedinShareButton';
import PinterestShare from './PinterestShareButton';
import RedditShare from './RedditShare';
import WhatsAppShare from './WhatsAppShare';

const ShareSectionContainer = tw.div`flex flex-col items-center justify-center pt-2 pb-2 text-left`;

const ShareSectionButton = styled.div`
  ${tw`flex flex-row items-center justify-center`}
  button {
    ${tw`flex-auto ml-2 text-center`}
  }
`;

const message = "Hey! Check this out. I just signed up on #1 Canada's Rewards and Cashback Platform.\nYou can also Sign Up and Earn $10 worth Coin using my referral link\n"
const ShareSection = ({referralLink = 'google.com'}) => {
    return (
        <ShareSectionContainer className="">
            <ShareSectionButton>
                <FacebookShare url={referralLink} title={message}/>
                <TwitterShare url={referralLink} title={message}/>
                <LinkedinShare url={referralLink} title={message}/>
                <PinterestShare url={referralLink} title={message}/>
                <WhatsAppShare url={referralLink} title={message}/>
                <RedditShare url={referralLink} title={message}/>
            </ShareSectionButton>
        </ShareSectionContainer>
    );
};

export default ShareSection;
