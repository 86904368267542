import { FacebookShareButton, FacebookIcon } from "react-share";
import React from "react";

const FacebookShare = ({ url, title, socialType }) => {
  return (
    <FacebookShareButton
      url={url}
      quote={title}
    >
      <FacebookIcon
        size={40}
        round
      />
    </FacebookShareButton>
  )
}

export default FacebookShare
