import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/TwoColumnWithVideo';
import { useAuth } from '../hook/useAuth';
import MainFeature from 'components/features/TwoColWithButton.js';
import MainFeature2 from 'components/features/TwoColSingleFeatureWithStats2.js';
import DownloadApp from 'components/cta/DownloadApp.js';
import chefSky from 'images/sky.svg';
import TwoColWithSteps from '../components/features/TwoColWithSteps';
import SimpleSubscribeNewsletter from '../components/forms/SimpleSubscribeNewsletter';
import MiniCenteredFooter from '../components/footers/MiniCenteredFooter';
import ThreeColSimpleWithImage from '../components/blogs/ThreeColSimpleWithImage';


export default () => {
  const Subheading = tw.span`tracking-wider text-sm text-red-500 font-medium `;
  const HighlightedText = tw.span`bg-red-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        paragraph="#1 Canada"
        heading={<>Cashback & Rewards</>}
        description="Real Savings at stores around you!"
        imageSrc={chefSky}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Sign Up"
        watchVideoButtonText="What is Mapple Coin?"
      />
      <ThreeColSimpleWithImage />
      <TwoColWithSteps subheading={<Subheading>Get upto $100</Subheading>}/>

      <SimpleSubscribeNewsletter />
      <MainFeature2
        subheading={<Subheading>#1 Canada's Rewards & Cashback Platform</Subheading>}
        heading={
          <>
            Why <HighlightedText>Mapple Coin ?</HighlightedText>
          </>
        }
        primaryButtonText="Sign Up"
        primaryButtonUrl="/signup"
        imageInsideDiv={false}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/connetcrowd-dev.appspot.com/o/5869.jpg?alt=media&token=20857b0d-bbb5-47b6-93c9-0d0a4a515e90&ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />

      {/*<DownloadApp*/}
      {/*  text={*/}
      {/*    <>*/}
      {/*      Download the <HighlightedTextInverse>Mapple Coin App</HighlightedTextInverse> and start*/}
      {/*      your savings today*/}
      {/*    </>*/}
      {/*  }*/}
      {/*/>*/}

      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
