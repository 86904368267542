import { LinkedinShareButton, LinkedinIcon } from "react-share";
import React from "react";

const LinkedinShare = ({ url, title, socialType }) => {
  return (
    <LinkedinShareButton
      url={url}
      quote={title}
    >
      <LinkedinIcon
        size={40}
        round
      />
    </LinkedinShareButton>
  )
}

export default LinkedinShare;
