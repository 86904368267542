export const QueryParam = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export const storeInStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getValueFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};
