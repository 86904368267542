import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TagManager from 'react-gtm-module'
import Modal from "react-modal";
import { AuthProvider } from './hook/useAuth';
import './styles/globalStyles.css';
Modal.setAppElement("#root");


const tagManagerArgs = {

  gtmId: process.env.REACT_APP_GTM,
}

console.log(process.env.REACT_APP_FIREBASE_DATABASE_URL)

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);
