import tw from 'twin.macro';
import { ReactComponent as Logout } from 'feather-icons/dist/icons/log-out.svg';
import Dollar from '../../images/DollarSvg.svg';
import celebrate from '../../images/celebrate.gif';
import { css } from 'styled-components/macro';
import { ReactComponent as LoaderIcon } from 'feather-icons/dist/icons/loader.svg';
import { ReactComponent as UserIcon } from 'feather-icons/dist/icons/user.svg';

import styled from 'styled-components';
import { useAuth } from '../../hook/useAuth';

const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline shadow-md`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

const Testimonial = styled.div`
  ${tw`relative -mx-8 bg-auto bg-no-repeat bg-center bg-cover h-full flex flex-col`}
  background-image: url(${celebrate});
`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const UserProfile = styled.span`
${tw`flex items-center rounded-full p-4 rounded-full transition duration-300 bg-gray-200 bg-gray-400 focus:outline-none focus:shadow-outline shadow-md`}
  svg{
  ${tw`w-5 h-5 stroke-3`}
  } 
}
`;

const CustomerName = styled.h5`
  ${(props) =>
    css`
      ${props.fromPage === 'Home' ? tw`text-white` : tw`text-black`}
    `}
  ${tw`font-semibold text-lg break-words tracking-wide`}
`;

const CustomerTitle = styled.span`
  ${tw`flex items-center space-x-4`}
  p {
    ${props =>
      props.fromPage === 'Home'
        ? tw`text-white`
        : tw`text-gray-800`}
    ${tw`text-lg font-semibold tracking-wide`}
  }
`;

export default ({
  imageUrl = 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
  imageAlt,
  fromPage = 'notHome',
  walletCoins = 0,
  isLoading = false
}) => {
  const { user, logout } = useAuth();
  if (isLoading) return <LoaderIcon tw="animate-spin" />;
  return (
    <Testimonial>
      <CustomerInfoAndControlsContainer>
        <CustomerInfo>
          <UserProfile>
            <UserIcon />
          </UserProfile>
          <CustomerTextInfo>
            <CustomerName fromPage={fromPage}>{user?.email}</CustomerName>
            <CustomerTitle fromPage={fromPage}>
              <img src={Dollar} alt="Dollar" />
              <p> ${walletCoins} </p>
            </CustomerTitle>
          </CustomerTextInfo>
        </CustomerInfo>
        <ControlButton onClick={logout}>
          <Logout />
        </ControlButton>
      </CustomerInfoAndControlsContainer>
    </Testimonial>
  );
};
