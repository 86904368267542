import React from 'react';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import illustration from 'images/signup.svg';
import googleIconImageSrc from 'images/google-icon.png';
import { ReactComponent as Loader } from 'feather-icons/dist/icons/loader.svg';

import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import { useAuth } from '../hook/useAuth';
import { Alert } from '../components/misc/alert';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Logo from '../images/mapple_logo.png';
import fbIconImageSrc from '../images/facebook-logo.svg';
import { Link, useHistory } from 'react-router-dom';
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const LogoImage = tw.img`h-12 w-auto`;

const Content = tw.div`max-w-screen-xl m-0 sm:mx-5 sm:my-5 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h4`text-2xl xl:text-3xl font-semibold text-gray-700`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 cursor-pointer hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0 cursor-pointer`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center shadow-xl`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


export default ({
  logoLinkUrl = '/',
  illustrationImageSrc = illustration,
  headingText = 'Sign up to mappleCoin',
  submitButtonText = 'Sign Up',
  SubmitButtonIcon = SignUpIcon,
  tosUrl = 'termsOfServicePage',
  privacyPolicyUrl = 'privacyPolicy',
  signInUrl = '/login'
}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const { register,googleLogin, fbLogin } = useAuth();
  const history = useHistory();

  const googleLoginBtn = async() => {
    try {
      await googleLogin();
      setShowLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error.message);
      setShowError(error?.message);
      setShowLoading(false);
    }
  }
  const fbLoginBtn = async() => {
    try {
      await fbLogin();
      setShowLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error.message);
      setShowError(error?.message);
      setShowLoading(false);
    }
  }

  const socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      onClick: () => googleLoginBtn(),
      text: 'Sign up With Google',
      url: 'https://google.com'
    },
    {
      iconImageSrc: fbIconImageSrc,
      text: 'Sign up With Facebook',
      onClick: () => fbLoginBtn(),
      url: 'https://fb.com'
    }
  ];

  const SignUpAction = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    try {
      await register(email, password);
      setShowLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error.message);
      setShowError(error?.message);
      setShowLoading(false);
    }
  };
  return (
    <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <Link to={logoLinkUrl}>
            <LogoImage src={Logo} />
          </Link>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} onClick={socialButton.onClick}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt="" />
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer>
              <DividerTextContainer/>
              <Form onSubmit={SignUpAction}>
                {showError && <Alert message={showError}/>}
                <Input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                  }}
                  required
                />
                <Input
                  type="password"
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                  }}
                  placeholder="Password"
                  required
                />
                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                  {showLoading && (
                    <div tw="animate-spin">
                      <Loader />
                    </div>
                  )}
                </SubmitButton>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by mapple's{' '}
                  <Link to={tosUrl} tw="border-b border-gray-500 border-dotted">
                    Terms of Service {' '}
                  </Link>
                  and its{' '}
                  <Link to={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </Link>
                </p>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Already have an account?{' '}
                  <Link
                    to={signInUrl}
                    tw="font-semibold tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500">
                    Sign In
                  </Link>
                </p>
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
    </AnimationRevealPage>
  );
};
