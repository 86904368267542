import React from 'react';
import TwoColContactUsWithIllustrationFullForm from '../components/forms/TwoColContactUsWithIllustrationFullForm';
import axios from 'axios';

export default () => {

  const [formValue, setformValue] = React.useState({
    email: '',
    name: '',
    subject: '',
    message: ''
  });
  const [success, setSuccess] = React.useState(false);

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }
  const formAction = async(event) => {
    // store the states in the form data
    event.preventDefault();
    try {
      // make axios post request
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/contact_us`,
        data: formValue,
        headers: {
          "Content-Type": "application/json"
        }
      }).then((response) => {
        console.log(response);
        setSuccess(true)
        setformValue({
          email: '',
          name: '',
          subject: '',
          message: ''
        });
      });
    } catch(error) {
      console.log(error)
    }
  }
  return (
    <>
      <TwoColContactUsWithIllustrationFullForm formAction={formAction} handleChange={handleChange} formValue={formValue} success={success}/>
    </>
  )
};
