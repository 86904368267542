import { WhatsappIcon, WhatsappShareButton } from "react-share";
import React from "react";


const WhatsAppShare = ({ url, title, socialType }) => {
  return (
    <WhatsappShareButton
      url={url}
      title={title}
    >
      <WhatsappIcon
        size={40}
        round
      />
    </WhatsappShareButton>
  )
}

export default WhatsAppShare;
