import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';

import Header from '../headers/light.js';

import ReactModalAdapter from '../../helpers/ReactModalAdapter.js';
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';

import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/dot-pattern.svg';
import Sky from '../../images/LongCart.svg';
import { useAuth } from '../../hook/useAuth';
import ReferralLink from '../misc/referralLink';
import { useFetch } from '../../helpers/useFetch';
import { Alert } from '../misc/alert';
import { getValueFromStorage, QueryParam, storeInStorage } from '../misc/shareButtons/queryParam';
import { Link } from 'react-router-dom';

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-no-repeat bg-center lg:bg-cover md:bg-contain`}
  background-image: url(${Sky});
`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gray-900 opacity-50`;


const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-red-400 transition duration-300 hocus:text-red-600 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-bold text-lg leading-none`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const HeadingBg = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-3xl  lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;
const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium lg:text-sm md:text-lg`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const PrimaryAction = tw.h3`py-3 text-sm sm:text-base sm:px-8 sm:py-4 bg-gray-100 text-red-500 font-bold rounded shadow transition duration-300 hocus:bg-red-500 hocus:text-gray-100 focus:shadow-outline`;

const TwoColWithVideo = ({
  paragraph,
  heading,
  description,
  primaryButtonText = 'Get Started',
  watchVideoYoutubeUrl = 'https://www.youtube.com/embed/y82G6S9p9Qc?autoplay=1'
}) => {
  const [getLoggedInStatus, setLoggedInStatus] = useState(false);

  useEffect(() => {
    const getReferral_id = QueryParam.referral_id;
    getReferral_id && storeInStorage('referral_id', getReferral_id);
  }, [QueryParam.referral_id]);

  // Bunch of step when seeing referral link
  const getReferral_id = getValueFromStorage('referral_id');

  const { user, getToken, getUid, uid, idToken } = useAuth();

  useEffect(() => {
    user && setLoggedInStatus(true);
  }, [user]);

  if (getLoggedInStatus) {
    (async () => {
      await getToken();
      await getUid();
    })();
  }

  const { data, loading, error } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}/auth`, {
    firebase_token: idToken,
    uid,
    ...(getReferral_id && { referral_by: getReferral_id })
  });

  if (error) return <Alert message={JSON.stringify(error)} />;

  return (
    <>
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <Header fromPage="Home" walletCoins={data?.wallet_coins} isLoading={loading} />
          <TwoColumn>
            <LeftColumn>
              <Notification>{paragraph}</Notification>
              <HeadingBg>
                <span>{heading}</span>
                <br />
                <SlantedBackground>{description}</SlantedBackground>
              </HeadingBg>
              <Actions>
                {!user ? (
                  <>
                    <Link
                      to="/signup"
                      tw={
                        'px-8 py-3 font-bold rounded bg-red-500 text-white hocus:bg-red-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300'
                      }>
                      {primaryButtonText}
                    </Link>
                  </>
                ) : (
                  <PrimaryAction href="#">Mobile App coming soon</PrimaryAction>
                )}
              </Actions>
            </LeftColumn>
            <RightColumn>
              {user ? (
                <ReferralLink referralLink={data?.referral_id} />
              ) : (
                <StyledResponsiveVideoEmbed
                  url="https://www.youtube.com/embed/y82G6S9p9Qc?autoplay=1"
                  background="transparent"
                />
              )}
            </RightColumn>
          </TwoColumn>
          <StyledModal
            closeTimeoutMS={300}
            className="mainHeroModal"
            shouldCloseOnOverlayClick={true}>
            <div className="content">
              <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
            </div>
          </StyledModal>
        </HeroContainer>
      </Container>
    </>
  );
};

export default TwoColWithVideo;
