import { useEffect, useState } from 'react';
import axios from 'axios';

export const useFetch = (url, dataParam) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dataParam.firebase_token &&
      axios
        .post(url, dataParam)
        .then((res) => setData(res.data))
        .catch((err) => setError(err?.response?.data))
        .finally(() => setLoading(false));
  }, [url, dataParam.firebase_token]);

  return { data, error, loading };
};
