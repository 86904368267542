import React from 'react';
import TermsOfService from '../pages/TermsOfService';

export default () => {
  return (
    <>
      <TermsOfService />
    </>
  )
};
