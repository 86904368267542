import { ReactComponent as AlertIcon } from 'feather-icons/dist/icons/alert-triangle.svg';

export const Alert = ({ message="Something went wrong" }) => (
  <>
    <div
      className="bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full"
      role="alert">
      <span className="mr-2 fill-current">
        <AlertIcon />
      </span>
      {message}
    </div>
  </>
);
