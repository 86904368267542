import 'tailwindcss/dist/base.css';
import 'tailwindcss/dist/components.css';
import 'tailwindcss/dist/utilities.css';

import 'styles/globalStyles.css';
import React from 'react';

import Home from 'demos/home.js';
import PrivacyPolicy from 'demos/PrivacyPolicy.js';
import TermsOfServicePage from 'demos/TermsOfServicePage.js';
import ContactUs from 'demos/ContactUs.js';
import Login from 'demos/Login';
import Signup from 'demos/Signup';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './hook/scrollToTop';

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/privacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/termsOfServicePage">
          <TermsOfServicePage />
        </Route>
        <Route path="/contactus">
          <ContactUs />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

