import React from 'react';
import PrivacyPolicy from '../pages/PrivacyPolicy';

export default () => {
  return (
    <>
      <PrivacyPolicy />
    </>
  )
};
