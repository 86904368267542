import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import MiniCenteredFooter from '../components/footers/MiniCenteredFooter';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: Feb 10, 2022</p>

            <p>Please read these terms and conditions carefully before using Our Service.</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE, BUT ARE NOT LIMITED TO, VARIOUS LIMITATIONS AND EXCLUSIONS, AND INDEMNITIES.
            </p>
            <p>
              Your use of the Website is subject to these Terms and Conditions. If you are not willing to be bound by each and every term or condition, or if any representation made herein by you is not true, you may not use, and must cease using, the Website.
            </p>

            <h2>Amendments to these Terms and Conditions.</h2>
            <p>We reserve the right to amend these Terms and Conditions at any time without notice to you, but we will use reasonable efforts to publish each amendment before such amendment becomes effective. We will ensure that the latest, fully amended version of these Terms and Conditions is published on the Website. You are responsible for regularly reviewing the Website to obtain timely notice of such amendments. If any amendment is unacceptable to you, you may terminate the agreement between you and us regarding the use of the Website as set out in these Terms and Conditions. If you continue to use the Website after the effective date of each amendment, you will be conclusively deemed to have accepted such amended version of these Terms and Conditions.

            </p>
            <h2>Legal Capacity</h2>
            <p>You represent and warrant that you possess the legal right, capacity and ability to agree to these Terms and Conditions and use the Website in accordance with them. We understand the importance of protecting children's privacy online. The PwC websites covered by these Terms and Conditions are not intentionally designed for or directed at children 13 years of age or younger. It is PwC's policy never to knowingly collect or maintain information about anyone under the age of 13. If you are using the Website on behalf of a corporation or other organization, you represent and warrant that you have the ability to agree to these Terms and Conditions on behalf of such organization and all references to "you" throughout these Terms and Conditions will include such organization, jointly and severally with you personally.</p>

            <h2>License</h2>
            <p>We grant to you a non-exclusive, non-transferable, limited license only to use the Website, in accordance with the provisions set out in these Terms and Conditions. All rights not expressly granted to you in these Terms and Conditions are reserved by us and, if applicable, our licensors.</p>

            <h2>Website Limitations.</h2>
            <p>
              The Website depends on the Internet, including networks, cabling, facilities and equipment that is not in our control; accordingly (i) any representation made by us regarding access performance, speeds, reliability, availability, use or consistency of the Website is on a "commercially reasonable efforts" basis, (ii) we cannot guarantee any minimum level regarding such performance, speed, reliability, availability, use or consistency, and (iii) data, messages, information or materials sent over the Internet may not be completely private, and your anonymity is not guaranteed.
            </p>
            <p>
              Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms
              and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the
              Service.
            </p>
            <p>
              By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
              with any part of these Terms and Conditions then You may not access the Service.
            </p>
            <p>
              You represent that you are over the age of 18. The Company does not permit those under 18 to use the
              Service.
            </p>
            <p>
              Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
              Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
              use and disclosure of Your personal information when You use the Application or the Website and tells You
              about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before
              using Our Service.
            </p>

            <h1>DISCLAIMERS, LIMITS OF LIABILITY AND INDEMNITIES.</h1>
            <h4>
              YOU ACKNOWLEDGE AND AGREE THAT:

              <ol>
                <li>
                  (i) ALL USE OF THE WEBSITE PROVIDED BY US IS AT YOUR OWN RISK;
                </li>
                <li>
                  (ii) THE WEBSITE MAY CONTAIN LINKS TO OTHER WEBSITES, WHICH ARE PROVIDED SOLELY AS A CONVENIENCE TO YOU AND THE INCLUSION OF ANY SUCH LINK DOES NOT IMPLY ENDORSEMENT, INVESTIGATION OR VERIFICATION BY US OF SUCH WEBSITES OR THE INFORMATION CONTAINED THEREIN; AND
                </li>
                <li>
                  (iii) THE WEBSITE PROVIDED UNDER THESE TERMS AND CONDITIONS IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
                </li>
              </ol>

            </h4>
            <p>
              WE MAKE NO CONDITIONS, WARRANTIES OR REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, USABILITY, SECURITY, QUALITY, CAPACITY, PERFORMANCE, AVAILABILITY, TIMELINESS OR ACCURACY OF THE WEBSITE OR ANY OTHER PRODUCTS SUPPLIED UNDER THESE TERMS AND CONDITIONS. WE EXPRESSLY DISCLAIM ALL CONDITIONS, WARRANTIES AND REPRESENTATIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING IMPLIED CONDITIONS OR WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, DURABILITY, TITLE AND NON-INFRINGEMENT, WHETHER ARISING BY USAGE OF TRADE, BY COURSE OF DEALING, BY COURSE OF PERFORMANCE, AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE HOWSOEVER.
            </p>
            <p>
              We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites
              or services that You visit.
            </p>

            <h1>NO LIABILITY</h1>
            <p>
              NOTWITHSTANDING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, IN NO EVENT WILL WE, OUR AFFILIATES, OR OUR CONTROLLING PARTIES, AGENTS, EMPLOYEES, SUPPLIERS, RESELLERS AND DISTRIBUTORS (COLLECTIVELY, IN THESE CAPITALIZED SECTIONS, "WE" OR "US") BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OR LOSSES WHATSOEVER, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OPPORTUNITY, EARNINGS, USE OR DATA, ARISING DIRECTLY OR INDIRECTLY FROM OR RELATED TO THESE TERMS AND CONDITIONS OR THE USE, OR NON-USE OF THE WEBSITE OR ANY SOFTWARE, REGARDLESS OF THE CAUSE OF ACTION AND EVEN IF ONE OR MORE OF US HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES, INCLUDING DAMAGES OR LOSSES ARISING FROM OR IN ANY WAY RELATED TO THE FOLLOWING: (i) ANY LOSS OR DAMAGE TO DATA ARISING DIRECTLY OR INDIRECTLY OUT OF USE OR NON-USE OF THE WEBSITE OR RELATED COMPONENT; (ii) DELAYS, ERRORS, INTERRUPTIONS, MISTAKES, OMISSIONS, NON-DELIVERY, INCORRECT DELIVERY, OR DEFECTS ON OR RELATED TO THE WEBSITE. WITHOUT RESTRICTING THE FOREGOING, IF CIRCUMSTANCES ARISE IN WHICH YOU OR ANOTHER PARTY IS ENTITLED TO RECOVER DAMAGES FROM ONE OR MORE OF US, THE AGGREGATE LIABILITY OF US FOR DAMAGES IS LIMITED TO THE AMOUNT PAID TO US BY YOU THROUGH THE WEBSITE DURING THE SIX-MONTH PERIOD PRECEDING THE CIRCUMSTANCES IN WHICH SUCH LIABILITY ARISES.
            </p>
            <p>Upon termination, Your right to use the Service will cease immediately.</p>

            <h1><strong>GENERAL TERMS</strong></h1>
            <h1>Enforceability</h1>
            <p>
              Your use of the Website and the content and features accessed through the Website constitute your electronic signature to the agreement set out in these Terms and Conditions and your consent to enter into agreements with us electronically. This Agreement will be deemed to satisfy any writings requirements of any applicable law, notwithstanding that the agreement set out in these Terms and Conditions is written and accepted electronically. All contracts between you and us completed electronically will be deemed for all legal purposes to be in writing and legally enforceable as a signed written agreement.
            </p>


            <h1>Interpretation</h1>
            <p>
              In these Terms and Conditions, (i) the captions and headings are for convenience only and do not constitute substantive matter and are not to be construed as interpreting the contents of these Terms and Conditions, (ii) the word "including", the word "includes" and the phrase "such as", when following a general statement or term (whether or not non-limiting language such as "without limitation" or "but not limited to" or other words of similar import are used with reference thereto), is not to be construed as limiting, and the word "or" between two or more listed matters does not imply an exclusive relationship between the matters being connected, and (iii) all references to website addresses or URLs will also include any successor or replacement websites containing substantially similar information as the referenced website(s).
            </p>
            <h1>Waiver of Rights and Remedies.</h1>
            <p>
              Our failure to insist upon or enforce strict performance of any provision of these Terms and Conditions will not be construed as a waiver of any provision or right. Neither the course of conduct between you and us nor trade practice will act to modify any provision of these Terms and Conditions. Our rights, powers and remedies in these Terms and Conditions, including without limitation the right to suspend, restrict or terminate any use of the Website, are cumulative and in addition to and not in substitution for any right, power or remedy that may be available to us at law or in equity.
            </p>


            <h1>Severability</h1>
            <p>
              If any provision of these Terms and Conditions is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability will attach only to such provision or part thereof and the remaining part of such provision and all other provisions hereof will continue in full force and effect.
            </p>

            <h1>Assignment and Inurement.</h1>
            <p>
              We may at any time assign our rights and obligations under these Terms and Conditions, in whole or in part, without notice to you. You may not assign these Terms and Conditions without our prior, written consent. These Terms and Conditions will inure to the benefit of and bind you and us and our respective personal and legal representatives, successors and permitted assigns.
            </p>

            <h1>For European Union (EU) Users</h1>
            <p>
              If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the
              country in which you are resident in.
            </p>

            <h1>United States Legal Compliance</h1>
            <p>
              You represent and warrant that (i) You are not located in a country that is subject to the United States
              government embargo, or that has been designated by the United States government as a “terrorist
              supporting” country, and (ii) You are not listed on any United States government list of prohibited or
              restricted parties.
            </p>

            <h1>Severability and Waiver</h1>
            <h2>Severability</h2>
            <p>
              If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
              interpreted to accomplish the objectives of such provision to the greatest extent possible under
              applicable law and the remaining provisions will continue in full force and effect.
            </p>

            <h2>Waiver</h2>
            <p>
              Except as provided herein, the failure to exercise a right or to require performance of an obligation
              under this Terms shall not effect a party's ability to exercise such right or require such performance at
              any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
            </p>

            <h1>Translation Interpretation</h1>
            <p>
              These Terms and Conditions may have been translated if We have made them available to You on our Service.
            </p>
            <p>You agree that the original English text shall prevail in the case of a dispute.</p>

            <h1>Changes to These Terms and Conditions</h1>
            <p>
              We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision
              is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms
              taking effect. What constitutes a material change will be determined at Our sole discretion.
            </p>
            <p>
              By continuing to access or use Our Service after those revisions become effective, You agree to be bound
              by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
              website and the Service.
            </p>

            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

            <ul>
              <li>By email: support@mapplecoin.com</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
