import React from 'react';
import tw from 'twin.macro';
import { PrimaryButton as PrimaryButtonBase } from './Buttons';
import { ReactComponent as CopyIcon } from 'feather-icons/dist/icons/clipboard.svg';
import { ReactComponent as QuotesLeftIconBase } from '../../images/quotes-l.svg';
import ShareSection from './shareButtons/ShareSection';

const ColumnHeading = tw.h5`uppercase font-bold`;

const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16 w-full h-full text-center`;

const Testimonial = tw.div`max-w-lg rounded-b md:rounded-none relative bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;

const QuotesLeftIcon = tw(
  QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const SubscribeForm = tw.form`mt-2 lg:mt-4 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`flex mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;
const CopyUrl = tw.div`bg-gray-300 text-gray-900 px-2 py-2 rounded truncate sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;

function ReferralLink({ referralLink = 'google.com' }) {
const [copied, setCopied] = React.useState('COPY');
  const copyToClipboard = (text) => {
    if (navigator && navigator.clipboard) navigator.clipboard.writeText(text)
      .then(() => { setCopied('COPIED') })
      .catch((error) => { alert(`Copy failed! ${error}`) });
  }
  const referralLinkUrl = `https://${window.location.host}?referral_id=${referralLink}`;

  const handleCopy = (e) => {
    e.preventDefault();
    copyToClipboard(referralLinkUrl);
  }

  return (
    <ImageContainer>
      <Testimonial>
        <ColumnHeading>Invite friends and earn $100</ColumnHeading>
        <QuotesLeftIcon />
        <ShareSection referralLink={referralLinkUrl}/>
        <SubscribeForm>
          <CopyUrl>{referralLinkUrl}</CopyUrl>
          <SubscribeButton type="submit" onClick={handleCopy}>{copied}<CopyIcon/></SubscribeButton>
        </SubscribeForm>
      </Testimonial>
    </ImageContainer>
  );
}

export default ReferralLink;
