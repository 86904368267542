import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { SectionHeading as HeadingTitle } from '../misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-3.svg';
import { ReactComponent as EarnIcon } from '../../images/toEarn.svg';
import { ReactComponent as ScanIcon } from '../../images/Scan.svg';
import { ReactComponent as DiscoverIcon } from '../../images/discover.svg';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = styled.div`
  ${tw`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs text-center`}
  svg {
    ${tw`w-full h-80 lg:h-64 mr-1 rounded`}
  }
`;

const Category = tw.div`mt-4 text-secondary-100 font-bold text-lg tracking-widest uppercase`;
const Title = tw.h4`mt-2 leading-relaxed font-normal text-lg`;


const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: <DiscoverIcon />,
      category: 'Discover the merchant',
      title: 'Choose any merchants near you ',
    },
    {
      imageSrc: <ScanIcon />,
      category: 'Upload Selfie & Bill',
      title: 'Click a Selfie & Scan a Bill',
    },
    {
      imageSrc: <EarnIcon />,
      category: 'Start Earning',
      title: 'Get Upto 100% Cashback & Rewards',
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>How to get started ?</HeadingTitle>
          <HeadingDescription>3 step guide </HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                {post.imageSrc}
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
