import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../hook/useAuth';

const useRedirect = (component) => {
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const { user } = useAuth();

  useEffect(() => {
    user && setRedirectToReferrer(true);
  }, [user]);

  if (redirectToReferrer) {
    return <Redirect to="/" />;
  }
  else {
    return component;
  }
};

export default useRedirect;
