import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
});

const AuthContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [uid, setUid] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const auth = firebase.auth();
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };
  const googleLogin = () => {
    var baseProvider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(baseProvider).then((result) => {
      setUser(result.user);
      return true;
    });
  };
  const fbLogin = () => {
    var fbBaseProvider = new firebase.auth.FacebookAuthProvider();
    return auth.signInWithPopup(fbBaseProvider).then((result) => {
      setUser(result.user);
      return true;
    });
  };

  const register = async (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password).then((result) => {
      setUser(result.user);
      return true;
    });
    //   return user;
  };
  const signInWithEmailLink = (email, code) => {
    return auth.signInWithEmailLink(email, code).then((result) => {
      setUser(result.user);
      return true;
    });
  };

  const logout = () => {
    return auth.signOut().then(() => {
      setUser(null);
      localStorage.clear();
    });
  };

  const getToken = auth.onAuthStateChanged(function(user) {
    if (user) {
      user.getIdToken().then(function(data) {
        setIdToken(data);
      });
    }
  });

  const getUid = auth.onAuthStateChanged(function(user) {
    if (user) {
      setUid(user?.uid);
    }
  });

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setIsAuthenticating(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const values = {
    user,
    isAuthenticating,
    signInWithEmailLink,
    login,
    register,
    googleLogin,
    fbLogin,
    logout,
    getToken,
    idToken,
    uid,
    getUid
  };

  return (
    <AuthContext.Provider value={values}>{!isAuthenticating && children}</AuthContext.Provider>
  );
};
