import { RedditShareButton, RedditIcon } from "react-share";
import React from "react";


const RedditShare = ({ url, title, socialType }) => {
  return (
    <RedditShareButton
      url={url}
      title={title}
    >
      <RedditIcon
        size={40}
        round
      />
    </RedditShareButton>
  )
}

export default RedditShare;
