import { TwitterShareButton, TwitterIcon } from "react-share";
import React from "react";


const TwitterShare = ({ url, title, socialType }) => {
  return (
    <TwitterShareButton
      url={url}
      title={title}
    >
      <TwitterIcon
        size={40}
        round
      />
    </TwitterShareButton>
  )
}

export default TwitterShare;
