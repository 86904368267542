import { PinterestShareButton, PinterestIcon } from "react-share";
import React from "react";

const PinterestShare = ({ url, title, socialType }) => {
  return (
    <PinterestShareButton
      url={url}
      quote={title}
    >
      <PinterestIcon
        size={40}
        round
      />
    </PinterestShareButton>
  )
}

export default PinterestShare;
